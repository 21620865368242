// ProtectedRoute.tsx
import React, {useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import {TextButton} from "../Buttons/Buttons";

interface ProtectedRouteProps {
    element: React.ReactElement;
}

const PASSWORD_HASH = '52c0bf5d9626550c7cdef452fb9e13d3a740bb614223c6b17d92fe615356fb57';
const AUTH_KEY = 'authToken'; // Ключ для хранения токена


const generateToken = (password: string) => {
    const timestamp = new Date().getTime();
    const data = `${password}:${timestamp}`;
    return CryptoJS.AES.encrypt(data, PASSWORD_HASH).toString();
};

const validateToken = (token: string) => {
    try {
        const decrypted = CryptoJS.AES.decrypt(token, PASSWORD_HASH).toString(CryptoJS.enc.Utf8);
        const [password, timestamp] = decrypted.split(':');
        const tokenAge = new Date().getTime() - parseInt(timestamp, 10);

        // Срок действия токена: 1 час (3600000 мс)
        if (tokenAge > 3600000) {
            return false; // Токен истек
        }

        const hash = CryptoJS.SHA256(password).toString();
        return hash === PASSWORD_HASH; // Проверка пароля
    } catch {
        return false; // Ошибка декодирования
    }
};


const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Проверка токена при загрузке
        const token = localStorage.getItem(AUTH_KEY);
        if (token && validateToken(token)) {
            setAuthenticated(true);
        }
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const hash = CryptoJS.SHA256(password).toString();
        if (hash === PASSWORD_HASH) {
            const token = generateToken(password);
            localStorage.setItem(AUTH_KEY, token); // Сохраняем токен
            setAuthenticated(true);
        } else {
            setError('Неверный пароль');
        }
    };

    if (authenticated) {
        return element;
    }

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.9)",
                zIndex: 100,

                /* Главное — свойства Flexbox */
                display: "flex",
                justifyContent: "center", // по горизонтали
                alignItems: "center"       // по вертикали
            }}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                style={{
                    width: "500px",
                    margin: "100px auto",
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "4px",
                    textAlign: "center"
                }}
            >

                <div >
                    <h2>Требуется пароль</h2>
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError('');
                            }}
                            placeholder="Введите пароль"
                            style={styles.input}
                        />
                        <button type="submit" style={styles.button}>Войти</button>
                    </form>
                    {error && <p style={styles.error}>{error}</p>}
                </div>


            </div>
        </div>
    )
        ;
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
    },
    form: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
    },
    input: {
        padding: '10px',
        marginBottom: '10px',
        width: '200px',
        fontSize: '16px',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginTop: '10px',
    },
};

export default ProtectedRoute;
