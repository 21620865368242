import React, {Component} from "react";
import ShowButton from "./Buttons/ShowButton";
import {TextButton} from "./Buttons/Buttons";


class KMMemories extends Component<any, any>{

    render() {
        return <>
            <br></br>
            <br></br>
            <br></br>
            <ShowButton label="2024. Беларусь.">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/iFyRd5Vb7h0?si=-9bs83cyuUvapRgh"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <br></br>
                <br></br>
                <br></br>

            </ShowButton> <ShowButton label="2021. Сочи + Абхазия.">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/zGvDOaDWDSM?si=vogsKdUuz8_9sUHG"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <br></br>
                <br></br>
                <br></br>

            </ShowButton>

            <ShowButton label="2018. Питер.">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/uCpAAew9jdc?si=Z1eCMjawj4b8vn5I"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <br></br>
                <br></br>
                <br></br>
            </ShowButton>


            {/*<ShowButton label="Steam 2023"> <img src="/stat/steam2023.jpg"></img></ShowButton>

            <ShowButton label="Steam 2024"> <img src="/stat/steam2024.jpg"></img></ShowButton>*/}
        </>;
    }
}


export default KMMemories