import React, {Component} from "react";
import {TextButton} from "./Buttons";
import "./Styles/ShowButton.css"

interface ShowButtonState{
	opened:boolean;
}interface ShowButtonProps {
	label: string;
	children: React.ReactNode;
}

class ShowButton extends Component<ShowButtonProps, ShowButtonState> {
	constructor(props: ShowButtonProps) {
		super(props);
		this.state = {
			opened: false,
		};
	}

	render() {
		return (
			<div className="ShowButtonHolder">
				<TextButton
					label={this.props.label}
					function={() => this.setState({ opened: !this.state.opened })}
				/>
				<br />
				<br />
				<div
					className="FitContent"
					style={{ display: this.state.opened ? 'inherit' : 'none' }}
				>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default ShowButton;
