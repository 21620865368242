import React, {Component} from "react";
import ShowButton from "./Buttons/ShowButton";
import {TextButton} from "./Buttons/Buttons";


class KMMemories extends Component<any, any>{

    render() {
        return <>
            <br></br>
            <br></br>
            <br></br>
            <TextButton
                label="Универ 2019-2023"
                function={() => window.open('https://disk.yandex.ru/d/VPaUtrc6QN_Wzw', '_blank')}
            />

            <br></br>
            <br></br>
            <TextButton
                label="Др Дани 9-11 августа 2023"
                function={() => window.open('https://disk.yandex.ru/d/vYxDZSPDRjAP-Q', '_blank')}
            />

            {/*<ShowButton label="Steam 2023"> <img src="/stat/steam2023.jpg"></img></ShowButton>

            <ShowButton label="Steam 2024"> <img src="/stat/steam2024.jpg"></img></ShowButton>*/}
        </>;
    }
}


export default KMMemories