import React, { Component } from "react";
// Предположим, у вас есть 5 отдельных JSON-файлов (easy, normal и т.д.)
// Для примера импортируем два:
import easyPresets from "./hpgroll/easy.json";
import normalPresets from "./hpgroll/medium.json";
import hardPresets from "./hpgroll/ultimate.json";
import aboveMediumPresets from "./hpgroll/abovemedium.json";
import generalPresets from "./hpgroll/general.json";
import questPresets from "./hpgroll/quest.json";
import {TextButton} from "./Buttons/Buttons";
import sectorsDetails from "./hpgroll/sectors_details.json";
import ShowButton from "./Buttons/ShowButton"; // Импорт JSON

// Если вы используете TypeScript, объявляем интерфейс для одного элемента JSON
// (Если у вас JS — можно опустить, но для наглядности оставлю)
interface Preset {
    index: number;
    name: string;
    games: string; // "Game1\nGame2\nGame3"

}

const difficultyDictionary: Record<string, string> = {
    easy: "Легкий",
    normal: "Средний",
    aboveMedium: "Выше среднего",
    hard: "Сложный",
    general: "Общий сложный",
    quest: "Квестовый"
};
const difficultyColors: Record<string, string> = {
    easy: "cyan",
    normal: "yellow",
    aboveMedium: "orange",
    hard: "red",
    general: "red",
    quest: "black"
};

// Интерфейс для данных из sectors_details.json
interface Sector {
    name: string;
    difficulty: string;
    gameFeatures: any; // Укажите конкретный тип, если известен

}

interface IRandomRoll {
    type: number;
    id: string;
    name: string;
    difficulty: string;
}

class HpgRoll extends Component<{}, {
    isPopupOpen: boolean;
    selectedGame: string | null;
    gameImage: string | null;  // URL картинки (если нашли)
    steamLink: string | null; // <-- новое поле
    selectedSector: Sector | null;
    preset: Preset | null;
    history: IRandomRoll[];
    difficulty: string;
}> {
    // Начальное состояние
    constructor(props: {}) {
        super(props);
        this.state = {
            isPopupOpen: false,
            selectedGame: null,
            gameImage: null,
            steamLink: "",
            selectedSector: null,// <-- новое поле
            preset: null,
            history: [],
            difficulty: "easy",

        };
    }

    // Словарь сложностей и массивов (можно объявить как static выше или просто внутри класса)
    private difficultyMap: Record<string, Preset[]> = {
        easy: easyPresets as Preset[],
        normal: normalPresets as Preset[],
        hard: hardPresets as Preset[],
        aboveMedium: aboveMediumPresets as Preset[],
        general: generalPresets as Preset[],
        quest: questPresets as Preset[],
    };

    componentDidMount() {
        const saved = localStorage.getItem("roll_history")
        if (saved) {
            try {
                const parsed = JSON.parse(saved);

                // Проверяем, что это действительно массив (если ожидаем массив)
                if (Array.isArray(parsed)) {
                    this.setState({ history: parsed });
                } else {
                    console.warn("Неверный формат в localStorage для roll_history");
                }
            } catch (error) {
                console.error("Ошибка при парсинге roll_history из localStorage:", error);
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{
        isPopupOpen: boolean;
        selectedGame: string | null;
        gameImage: string | null;
        steamLink: string | null;
        selectedSector: Sector | null;
        preset: Preset | null;
        history: IRandomRoll[]
    }>, snapshot?: any) {
        localStorage.setItem("roll_history", JSON.stringify(this.state.history))
    }




    // Метод для получения случайной игры
    private handleClick = (difficulty: keyof typeof this.difficultyMap) => {
        const presets = this.difficultyMap[difficulty];
        if (!presets || presets.length === 0) {
            alert("Нет пресетов для этой сложности!");
            return;
        }

        // Случайный пресет
        const randomPresetIndex = Math.floor(Math.random() * presets.length);
        const randomPreset = presets[randomPresetIndex];

        // Делим строку "games" по переносам строк, получаем массив
        const gamesList = randomPreset.games
            .split("\n")
            .map((g) => g.trim())
            .filter(Boolean);

        if (gamesList.length === 0) {
            alert(`В пресете "${randomPreset.name}" нет игр!`);
            return;
        }


        // Случайная игра из массива
        const randomGameIndex = Math.floor(Math.random() * gamesList.length);
        const randomGame = gamesList[randomGameIndex];

        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today.getDate();
        const currentDate = date + "/" + month + " (" + today.getHours()+ ":" + today.getMinutes() +")";

        const newRoll: IRandomRoll = {
            id: String(currentDate),   // уникальный id
            name: randomGame,
            type: 0,
            difficulty: difficulty,
        };

        this.setState(
            {
                isPopupOpen: true,
                selectedGame: randomGame,
                gameImage: null, // сбросим картинку, пока не загрузили
                 preset: randomPreset,
                difficulty: difficulty,
        },
            () => {
                this.fetchGameImage(randomGame);
            }
        );

        this.setState(prev => ({
            history: [...prev.history, newRoll]
        }));


        //fetch("http://swathingsoap.ru:4514/?message=[swathingsoap.com/hpg4roll]    Кому-то выпала игра: " + randomGame).then((r) => console.log(r));
        const message = "[hpg4roll]\n\nКому-то выпала игра:\n" + randomGame;
        fetch("https://swathingsoap.ru/message?message=" + encodeURIComponent(message)).catch((a) => console.error(a));
    };

    private openFromHistory = (roll: IRandomRoll) => {
        this.setState({
            isPopupOpen: true,
            selectedGame: roll.name,
        });

        this.fetchGameImage(roll.name);

    };

    private openGame = (name: string) => {
        this.setState({
            isPopupOpen: true,
            selectedGame: name,
            difficulty:""
        });

        this.fetchGameImage(name);

    };

    // Закрыть попап
    private closePopup = () => {
        this.setState({
            isPopupOpen: false,
            selectedGame: null,
            gameImage: null,
            selectedSector: null
        });
    };

    private handleOverlayClick = () => {
        // Кликнули по оверлею (фону) — значит, закрываем попап.
        this.closePopup();
    };

    private async fetchGameImage(gameName: string) {
        try {
            const baseUrl = process.env.REACT_APP_RAWG_BASE_URL; // "https://api.rawg.io/api"
            const apiKey = process.env.REACT_APP_RAWG_KEY;       // ваш ключ

            // Сначала ищем игру
            const searchUrl = `${baseUrl}/games?key=${apiKey}&search=${encodeURIComponent(
                gameName
            )}&search_precise=true`;

            const searchRes = await fetch(searchUrl);
            if (!searchRes.ok) {
                console.error("Ошибка при поиске игры:", searchRes.status);
                return;
            }


            const searchData = await searchRes.json();


            if (!searchData?.results || searchData.results.length === 0) {
                console.warn("Не нашли игру по названию:", gameName);
                return;
            }

            // Берём ID первой найденной игры
            const gameId = searchData.results[0].id;

            // Второй запрос: детали
            const detailUrl = `${baseUrl}/games/${gameId}?key=${apiKey}`;
            const detailRes = await fetch(detailUrl);
            if (!detailRes.ok) {
                console.error("Ошибка при запросе деталей:", detailRes.status);
                return;
            }


            const detailData = await detailRes.json();
            if (!detailData?.background_image) {
                console.warn("Нет background_image у игры:", gameId);
                return;
            }

            const steamStore = detailData.stores.find(
                (s: any) => s.store?.slug === "steam"
            );
            let steamLink = null;

            if (steamStore) {
                // 1) Если RAWG вдруг вернёт непустой steamStore.url, используем его
                if (steamStore.url) {
                    steamLink = steamStore.url;
                }
                else if (detailData.steamAppId)
                {
                    steamLink = "https://store.steampowered.com/app/" + detailData.steamAppId;

                }
                else
                {
                    // 2) Иначе формируем ссылку на поиск в Steam по названию игры
                    steamLink = "https://store.steampowered.com/search/?term="
                        + encodeURIComponent(gameName);
                }
            }


            // Получили URL картинки — обновляем state
            this.setState({
                gameImage: detailData.background_image,
                steamLink: steamLink
            });
        } catch (error) {
            console.error("Ошибка при загрузке картинки:", error);
        }
    }

    private getRandomSector = (difficulty: string) => {
        const filteredSectors = sectorsDetails.filter(
            (sector: Sector) => sector.difficulty === difficulty
        );
        if (filteredSectors.length === 0) {
            alert("Нет доступных секторов для выбранной сложности!");
            return;
        }
        const randomSector =
            filteredSectors[Math.floor(Math.random() * filteredSectors.length)];


        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today.getDate();
        const currentDate = date + "/" + month + " (" + today.getHours()+ ":" + today.getMinutes() + ")";


        const newRoll: IRandomRoll = {
            id: String(currentDate),   // уникальный id
            name: randomSector.name,
            type: 1,
            difficulty: difficulty,
        };

        this.setState(prev => ({
            history: [...prev.history, newRoll]
        }));

        this.setState({ selectedSector: randomSector,
        isPopupOpen: true});

        const message = "[hpg4roll]\n\nКому-то выпал сектор:\n" + " [" + difficulty + "] " + randomSector.name;
        fetch("https://swathingsoap.ru/message?message=" + encodeURIComponent(message)).catch((a) => console.error(a));
    };

    private openSector = (name: string) => {
        const filteredSectors = sectorsDetails.filter(
            (sector: Sector) => sector.name === name);

        if (filteredSectors.length === 0) {
            alert("Нет доступных секторов для выбранного имени!");
            return;
        }

        this.setState({ selectedSector: filteredSectors[0],
            isPopupOpen: true});
    }

    renderGameFeatures(features: Record<string, any>) {
        const translations: Record<string, string> = {
            genre: "Жанр",
            length: "Длительность",
            preset: "Предустановка",
            price: "Цена",
            publisher: "Издатель",
            releaseDate: "Дата Релиза",
            score: "Оценка",
            segments: "Количество игр в ролле",
            special: "Особенности",
            tag: "Теги",
        };

        return (
            <ul>
                {Object.entries(features).map(([key, value], index) => {
                    const translatedKey = translations[key] || key;

                    let formattedValue;
                    if (key === "releaseDate") {
                        formattedValue = `от ${new Date(value.from).toLocaleDateString()} до ${new Date(value.to).toLocaleDateString()}`;
                    } else if (key === "length" || key === "price" || key === "score") {
                        formattedValue = `от ${value.from} до ${value.to}`;
                    } else if (key === "genre" || key === "tag") {
                        formattedValue = value.join(", ");
                    } else if (key === "preset"){
                        formattedValue = 'Общий сложный пресет';
                    }
                    else {
                        formattedValue = JSON.stringify(value, null, 2);
                    }

                    return (
                        <li key={key + index}>
                            <strong>{translatedKey}:</strong> {formattedValue}
                        </li>
                    );
                })}
            </ul>
        );
    }


    render() {
        const { isPopupOpen, selectedGame, gameImage, steamLink, selectedSector, preset, history, difficulty  } = this.state;

        var clonedHistory = [...history];
        clonedHistory.reverse();
        return (
            <>
                {/* Можно, конечно, стилизовать и разметку посложнее */}
                    <div style={{margin: "20px"}}>
                        <h2 style={{fontFamily: '"Exo 2"'}}>Случайная игра из пресета</h2>

                        <TextButton label="Легкий" function={() => {
                            this.handleClick("easy")
                        }}/>
                        <br/>
                        <br/>
                        <TextButton label="Средний" function={() => {
                            this.handleClick("normal")
                        }}/>
                        <br/>
                        <br/>
                        <TextButton label="Выше среднего" function={() => {
                            this.handleClick("aboveMedium")
                        }}/>
                        <br/>
                        <br/>
                        <TextButton label="Сложный" function={() => {
                            this.handleClick("hard")
                        }}/>
                        <br/>
                        <br/>
                        <h2 style={{fontFamily: '"Exo 2"'}}>Другие пресеты</h2>
                        <TextButton label="Общий сложный" function={() => {
                            this.handleClick("general")
                        }}/>
                        <br/>
                        <br/>
                        <TextButton label="Квестовый" function={() => {
                            this.handleClick("quest")
                        }}/>
                        <h2 style={{fontFamily: '"Exo 2"'}}>Случайный пресет и случайная игра </h2>
                        <TextButton label="Полный рандом" function={() => {
                            const difficulties = ["easy", "normal", "aboveMedium", "hard", "general", "quest"];
                            const randomIndex = Math.floor(Math.random() * difficulties.length);
                            const randomDifficulty = difficulties[randomIndex];
                            this.handleClick(randomDifficulty);
                        }}/>

                        {/* Остальные кнопки по аналогии: Hard, Insane, Custom и т.д. */}
                    </div>

                {/* Попап-окно, показываем, только если isPopupOpen */}
                    {isPopupOpen && (selectedGame || selectedSector) && (
                        <div
                            onClick={this.handleOverlayClick}
                            style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(0,0,0,0.9)",
                                zIndex: 100,

                                /* Главное — свойства Flexbox */
                                display: "flex",
                                justifyContent: "center", // по горизонтали
                                alignItems: "center"       // по вертикали
                            }}
                        >
                            <div
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    width: "500px",
                                    margin: "100px auto",
                                    backgroundColor: "#fff",
                                    padding: "20px",
                                    borderRadius: "4px",
                                    textAlign: "center"
                                }}
                            >

                                {selectedSector != null? (<>
                                    <h3>Cектор:</h3>
                                    <p><strong>Название:</strong> {selectedSector.name}</p>
                                    <p><strong>Особенности:</strong></p>
                                    {/*  <pre>{JSON.stringify(selectedSector.gameFeatures, null, 2)}</pre>*/}
                                    {this.renderGameFeatures(selectedSector.gameFeatures)}
                                </>) : (<>
                                </>)}

                                {selectedGame != null?
                                    (<>
                                        <p style={{fontFamily: '"Exo 2"',
                                            textShadow: `
      -1px -1px 0 #000,  
       1px -1px 0 #000,
      -1px  1px 0 #000,
       1px  1px 0 #000
    `, // Имитация обводки
                                            color: difficultyColors[difficulty],


                                        }}><strong>[{difficultyDictionary[difficulty]}]</strong></p>
                                        <p style={{fontFamily: '"Exo 2"'}}><strong>Пресет: {preset?.name}</strong></p>
                                        {/* <h3 style={{fontFamily: '"Exo 2"'}}>Случайная игра:</h3>*/}
                                        <p><strong>Игра: {selectedGame}</strong></p>

                                        {/* Если картинка загрузилась, показываем её. Можно добавить проверку на null */}
                                        {gameImage ? (
                                            <img
                                                src={gameImage}
                                                alt={selectedGame? selectedGame : ""}
                                                style={{maxWidth: "100%", borderRadius: "4px"}}
                                            />
                                        ) : (
                                            <p style={{fontStyle: "italic"}}>
                                                Загрузка картинки или картинка не найдена...
                                            </p>
                                        )}

                                        {steamLink && (
                                            <p>
                                                <a style={{fontFamily: '"Exo 2"'}} href={steamLink} target="_blank"
                                                   rel="noopener noreferrer">
                                                    Открыть в Steam
                                                </a>
                                            </p>
                                        )}

                                    </>)
                                    :(<></>)}




                                <TextButton label="Закрыть" function={() => {
                                    this.closePopup();
                                }}/>

                            </div>
                        </div>
                    )}

                    <div style={{margin: "20px"}}>
                        <h2 style={{fontFamily: '"Exo 2"'}}>Случайные сектора</h2>

                        <TextButton
                            label="Легкий"
                            function={() => this.getRandomSector("easy")}
                        />
                        <br/>
                        <TextButton
                            label="Средний"
                            function={() => this.getRandomSector("medium")}
                        />
                        <br/>
                        <TextButton
                            label="Выше среднего"
                            function={() => this.getRandomSector("aboveMedium")}
                        />
                        <br/>
                        <TextButton
                            label="Сложный"
                            function={() => {
                                this.handleClick("general")
                                this.getRandomSector("hard")
                            }}
                        />

                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h2 style={{fontFamily: '"Exo 2"'}}>Инфа:</h2>
                        <ShowButton key = "info list" label="Списки игр">
                            <>
                                {Object.entries(this.difficultyMap).map(([key, value]) => {
                                    return (
                                    <ShowButton key = {"test_" + key}  label={difficultyDictionary[key]}> <>

                                        {
                                            value.map((preset) => (
                                                <div style={{fontFamily: '"Exo 2"'}}>
                                                    <strong>{preset?.name}</strong>
                                                    <br/>
                                                    {preset?.games.split("\n")
                                                        .map((g,index) => (
                                                            <>
                                                                <a
                                                                    key={"info_"+index+preset?.name+g}
                                                                    onClick={() => {this.openGame(g)}}
                                                                    style={{cursor: 'pointer', textDecoration: 'underline'}}>{g}</a><br/>
                                                            </>
                                                        ))}
                                                    <br/>
                                                </div>
                                            ) )
                                        }
                                    </>
                                    </ShowButton>)
                                    }

                                )}
                            </>
                        </ShowButton>





                        <ShowButton key = "history" label="История роллов" >
                            <>
                                {clonedHistory.map((h,index) => (
                                    <a key={h.id + h.name + index} onClick={() => {
                                        h.type === 0? (
                                            this.openGame(h?.name)
                                        ):(
                                            this.openSector(h.name)
                                        )
                                    }}
                                       style={{cursor: 'pointer', textDecoration: 'underline'}}
                                    >
                                        {h.id}: {h.name}
                                        <br/>
                                    </a>

                                ))}
                            </>
                        </ShowButton>

                       {/* <h3>История роллов:</h3>
                        {history.map(h => (
                            <a key={h.id} onClick={() => this.openFromHistory(h)}
                               style={{cursor: 'pointer', textDecoration: 'underline'}}
                            >
                                {h.name}
                                <br/>
                            </a>

                        ))}*/}
                    </div>


            </>
        );
    }
}

export default HpgRoll;
