import React, {Component} from "react";
import ItemsLoader from "./ItemsLoader";
import Comments from "./Comments";
import ShowButton from "./Buttons/ShowButton";


class Wishlist extends Component<any, any>{

    render() {
        return <>
            <ShowButton label="Steam 2023"> <img src="/stat/steam2023.jpg"></img></ShowButton>

            <ShowButton label="Steam 2024"> <img src="/stat/steam2024.jpg"></img></ShowButton>
        </>;
    }
}


export default Wishlist